<template>
  <div class="main-body">
      <div class="bgimage-main">
          <img :src="bgimage" alt="" v-if="bgimage">
      </div>
      <div class="qr-code-img" v-if="codeqr">
          <img :src="codeqr" alt="">
      </div>
  </div>
<!--    <Barrage :words="boomWords" :font-color="fontColor" :font-size="fontSize" :line-interval="lineInterval" :height="400" />-->
</template>

<script setup>
import {webIndex, webNotice, wordloop} from "@/utils/apis";
import {onMounted, onUnmounted} from "vue";
import {ElMessage, ElNotification} from "element-plus";

import {ref} from "vue";
import Barrage from "@/components/common/Barrage";

let instance = null
const bgimage = ref("")
const codeqr = ref("")
const boomWords = ref([])
const fontColor = ref(['#6B7280', '#EF4444', '#F59E0B', '#10B981', '#3B82F6','#6366F1', '#8B5CF6', '#EC4899', '#FFF', '#000'])
const fontSize = ref(30)
const lineInterval = ref(30)

let wordsIns = null
const noticeQuery = () => {
    instance = setInterval(() => {
        noticeReceive()
    }, 3000)
}
const baberrageLoop = () => {
    wordsIns = setInterval(() => {
        wordsLoop()
    }, 3000)
}
const webData = async () => {
    try {
        const {data, msg} = await webIndex()
        bgimage.value = data.background_url
        codeqr.value = data.qr_code
    } catch (e) {
        console.log('e', e.message);
    }
}
const noticeReceive = async () => {
    try {
        const {data, msg} = await webNotice()
        if (data.length > 0) {
          ElMessage.success(data[0])
        }
    } catch (e) {
        console.log('e', e.message)

    }
}
const wordsLoop = async () => {
    try {
        const {data, msg } = await wordloop()
        boomWords.value = boomWords.value.concat(data)
    } catch (e) {
        console.log('error', e.message);
    }
}
webData()
onMounted(() => {
    // noticeQuery()
    // baberrageLoop()
})
onUnmounted(() => {
    clearInterval(instance)
    clearInterval(wordsIns)
})

</script>

<style scoped lang="scss">
html, body {
  padding: 0;
  margin: 0;
}
.main-body {
    width: 100%;
    height: 100vh;
    position: relative;
    &, & * {
      box-sizing: border-box;
    }
    .bgimage-main {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .qr-code-img {
        width: 20%;
        height: auto;
        position: absolute;
        bottom: 0.3%;
        right: 5%;
        img {
          width: 100%;
          height: 100%;
        }
    }
}

</style>